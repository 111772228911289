<template>
  <center-screen>
    <v-form @submit.prevent>
      <p>
        Please enter your email and we will send you a link to complete your
        password-reset operation.
      </p>
      <v-text-field
        v-model="email"
        :label="$t('user.email')"
        :rules="rules.email"
        name="email"
        prepend-icon="mdi-account-circle"
        type="text"
      />
    </v-form>

    <template v-slot:actions>
      <v-btn text color="primary" tile depressed @click="navigateToLogin">
        {{ $t("login.login") }}
      </v-btn>
      <v-spacer />
      <v-btn
        color="primary"
        tile
        depressed
        :loading="loading"
        @click="sendPasswordReset"
      >
        {{ $t("general.okay") }}
      </v-btn>
    </template>
  </center-screen>
</template>

<script>
import { mapActions } from "vuex";
import CenterScreen from "@/common/layouts/CenterScreen";

export default {
  name: "ForgotPassword",
  components: { CenterScreen },
  data() {
    return {
      loading: false,
      redirect: "/",
      email: null,
      rules: {
        email: [
          (v) => !!v || "Email is required",
          (v) => {
            const re = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gi;
            return !!v && re.test(String(v).toLowerCase());
          },
        ],
      },
    };
  },
  methods: {
    ...mapActions("auth", ["passwordReset"]),

    hitEnter(event) {
      if (event.keyCode === 13) {
        event.preventDefault();
        this.tryLogin();
      }
    },
    sendPasswordReset() {
      this.loading = true;
      this.passwordReset(this.email)
        .then(() => {
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    navigateToLogin() {
      this.$router.push({ name: "login" });
    },
  },
};
</script>
